import React, { useState } from 'react'
import { GoThreeBars } from 'react-icons/go'
import Menu from './Menu';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => setSidebar(!sidebar);
  return (
    <header className='w-full h-[70px] bg-white fixed top-0 z-30 xl:px-16 sm:px-6 flex items-center justify-between'>
      <h1 className='hover:text-orange-400 font-bold text-2xl'><a href="#">Jorgedev.pt</a></h1>
      <div className='xl:hidden sm:flex'>
        <GoThreeBars size={25} onClick={toggleSidebar} />
        <Menu sidebar={sidebar} toggleSidebar={toggleSidebar} />
      </div>
      <nav className='sm:hidden xl:flex'>
        <ul className='flex text-xl font-semibold'>
          <li className='mr-5'><a href="#">Home</a></li>
          <li className='mr-5'><a href="#about">About</a></li>
          <li className='mr-5'><a href="#projects">Projects</a></li>
          <li className='mr-5'><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar